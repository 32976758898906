<template>
  <div id="process-logistics-page">
    <Details
      :pageTitle="pageTitle"
      :description="description"
      :sections="sections"
    />
  </div>
</template>

<script>
import Details from '@/components/disciplines/DetailsPage.vue'
import DisciplinesStore from '@/stores/DisciplinesStore.js'
export default {
  components: {
    Details
  },
  data: () => ({
    pageTitle: DisciplinesStore.data.ProcessSupportLogistics.title,
    description: DisciplinesStore.data.ProcessSupportLogistics.description,
    sections: DisciplinesStore.data.ProcessSupportLogistics.sections
  })  
}
</script>

<style scoped>

</style>